import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { getDirection } from "../../../../../shared/src/lib/helpers/helpers";


@Component({
  template: ''
})
export abstract class FormInputBaseComponent {

  @Input('mode') mode: 'ng-model' | 'reactive' = 'reactive'

  @Input('formGroup') formGroup!: FormGroup
  @Input('controlName') controlName!: string;
  @Input('isSubmitted') isSubmitted!: boolean;

  @Input('label') label!: string;
  @Input('helpText') helpText!: string;
  @Input('placeholder') placeholder!: string;
  @Input('styleClass') styleClass!: string;
  @Input('formGroupClass') formGroupClass!: string;
  @Input('autocomplete') autocomplete!: string;

  // ng-model mode
  @Input() inputValue!: any | undefined;
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter<any>();


  get dir() { return getDirection() }

  protected onInputChange(value: any) {
    this.inputValueChange.emit(value);
  }

  reset() {
    this.inputValue = undefined;
    this.inputValueChange.emit(this.inputValue);
  }


  constructor() {

  }


  get isInvalid(): boolean | undefined {
    if (!this.formGroup || !this.controlName) {
      return undefined;
    }

    return this.formGroup.controls[this.controlName]?.invalid && this.isSubmitted;
  }

}
