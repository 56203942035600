import { RegionLookupResult } from '../../../../../openapi/model/regionLookupResult';
import { LookupsService } from '../../../../../openapi/api/lookups.service';
import { Component, OnInit } from '@angular/core';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { FormInputBaseComponent } from '../../form-input-base.component';

@Component({
  selector: 'app-city-selector',
  templateUrl: './city-selector.component.html',
})
export class CitySelectorComponent extends FormInputBaseComponent implements OnInit {

  regions: Array<RegionLookupResult> = [];

  groupedCities: RegionGroup[] = [];

  constructor(private lookupsService: LookupsService) {
    super()
  }

  ngOnInit(): void {
    this.lookupsService.cities().subscribe(response => {
      if (response.isSuccess && response.data) {
        this.regions = response.data!
        this.setGroups();
      }
    });
  }

  private setGroups() {
    if (!this.regions) {
      return;
    }

    this.groupedCities = this.regions.map(r => {
      return {
        label: r.name!,
        labelAr: r.nameAr!,
        value: r.id!,
        items: r.cities!.map(c => {
          return {
            value: c.id!,
            label: c.name!,
            labelAr: c.nameAr!,
          }
        })
      }
    });
  }

}

interface RegionGroup extends SelectItemGroup {
  labelAr: string
  items: CitySelectItem[]
}

interface CitySelectItem extends SelectItem {
  labelAr: string
}
